import { PagedRequest } from '@domain/services/paged-request';
import { Observable } from 'rxjs';
import { ShowPlatformContractsResponse } from '../dto/show.dto';
import {
  GetPlatformContractsQuery,
  GetPlatformContractsResponse,
} from '../dto/get.dto';
import {
  ListPlatformContractsQuery,
  ListPlatformContractsResponse,
} from '../dto/list.dto';
import {
  CreatePlatformContractsQuery,
  CreatePlatformContractsResponse,
} from '../dto/create.dto';
import {
  UpdatePlatformContractsQuery,
  UpdatePlatformContractsResponse,
} from '../dto/update.dto';
import { getPriceRangeOfPlatformContractsResponse } from '../dto/price.dto';

export abstract class PlatformContractsRepository implements PagedRequest {
  abstract show(id: number): Observable<ShowPlatformContractsResponse>;

  abstract get(
    query?: GetPlatformContractsQuery
  ): Observable<GetPlatformContractsResponse>;

  abstract list(
    query?: ListPlatformContractsQuery
  ): Observable<ListPlatformContractsResponse>;

  abstract create(
    payload: CreatePlatformContractsQuery
  ): Observable<CreatePlatformContractsResponse>;

  abstract update(
    payload: UpdatePlatformContractsQuery
  ): Observable<UpdatePlatformContractsResponse>;

  abstract getPriceRange(): Observable<getPriceRangeOfPlatformContractsResponse>;
}
